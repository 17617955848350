<template>
<div>
    <p style="margin-left: 20px; margin-right: 20px;">
      So genug mit den Fragen! Jetzt wird es ohne die Seite weitergehen!
    </p>
    <p style="margin-left: 20px; margin-right: 20px;">
      Falls du die UV-Lampe nicht gefunden hast, sie liegt im Kofferraum des Skodas.
    </p>
    <p style="margin-left: 20px; margin-right: 20px;">
      Suche in folgenden Zimmern nach weiteren Hinweisen. Ein Stift und Blatt Papier
      könnten dabei helfen.
      Kleiner Tipp: Dunkelheit hilft manchmal
    </p>
    <ul>
      <li>Küche</li>
      <li>Wohnzimmer</li>
      <li>Schlafzimmer</li>
      <li>Oben: Flur</li>
    </ul>
</div>
</template>

<script>
export default {
  name: 'SixthHint',
  props: {
  },
  methods: {
    goToSixthPart() {
      this.$router.push('/971ed08b-0012-4ecd-9771-e8df1098a1ea');
    },
  },
};
</script>

<style scoped>
p {
    margin-left: 10px;
    margin-right: 10px;
}
</style>

<template>
  <div>
    <div>
      <Question id="fifthPartQuestionOne" :question=this.fifthPartQuestionOne
                v-on:is-correct="checkStatusOfQuestion"/>
    </div>
    <b-button variant="warning" style="border-color: black; margin-top: 30px"
              :hidden="hideCheckButton">Prüfen
    </b-button>
    <b-button variant="success" v-on:click="goToFifthHint" :hidden="hideGoOnButton"
              style="border-color: black; margin-top: 30px">Weiter
    </b-button>
  </div>
</template>

<script>
import Question from '../components/Question.vue';
import questionApi from '../questionApi';
import api from '../questionCheckerAndChanger';

export default {
  name: 'FifthPart',
  components: {
    Question,
  },
  data() {
    return {
      fifthPartQuestionOne: {},
      questionOneCorrect: false,
      hideGoOnButton: true,
      hideCheckButton: false,
    };
  },
  methods: {
    goToFifthHint() {
      if (this.questionOneCorrect) {
        this.$router.push('/971ed08b-0012-4ecd-9771-e8df1098a1ea');
      }
    },
    checkStatusOfQuestion(questionStatus) {
      if (questionStatus.indexes[0] === '4' && questionStatus.indexes[1] === '0') {
        this.questionOneCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '4', '0', 'fifthPartQuestionOne');
        this.hideCheckButton = this.questionOneCorrect;
        this.hideGoOnButton = !this.hideCheckButton;
      }
    },
  },
  async mounted() {
    this.fifthPartQuestionOne = await questionApi.questionArray.getPartAndIndexQuestion(4, 0);
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="home">
    <img alt="Home Image" src="../assets/saw_mask.png">
    <h1>Willkommen zu Danis Rätselparadies</h1>
    <p style="margin-right: 30px; margin-left: 30px;">
      Deine Aufgabe ist es Fragen und Rätsel zu beantworten und zu lösen.
      Bitte schreibe mir bei Whatsapp wenn du mit einer Fragerunde fertig bist. Nach einer
      unbekannten Zeit bekommst du dann einen Link zum nächsten Rätsel.
      Na dann viel Erfolg und viel Spaß!
    </p>
    <b-button variant="success" v-on:click="goToFirstPart"
              style="border-color: black;">Weiter</b-button>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
  },
  methods: {
    goToFirstPart() {
      this.$router.push('/ef8f418e-90d2-11ec-b909-0242ac120002');
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

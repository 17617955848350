<template>
  <div>
    <div>
      <Question id="thirdPartQuestionOne" :question=this.thirdPartQuestionOne
                v-on:is-correct="checkStatusOfQuestion"/>
    </div>
    <b-button variant="warning" style="border-color: black; margin-top: 30px"
              :hidden="hideCheckButton">Prüfen
    </b-button>
    <b-button variant="success" v-on:click="goToThirdHint" :hidden="hideGoOnButton"
              style="border-color: black; margin-top: 30px">Weiter
    </b-button>
  </div>
</template>

<script>
import Question from '../components/Question.vue';
import questionApi from '../questionApi';
import api from '../questionCheckerAndChanger';

export default {
  name: 'ThirdPart',
  components: {
    Question,
  },
  data() {
    return {
      thirdPartQuestionOne: {},
      questionOneCorrect: false,
      hideCheckButton: false,
      hideGoOnButton: true,
    };
  },
  methods: {
    goToThirdHint() {
      if (this.questionOneCorrect) {
        this.$router.push('/72a2520e-2817-44c2-b502-71ce86b3f490');
      }
    },
    checkStatusOfQuestion(questionStatus) {
      if (questionStatus.indexes[0] === '2' && questionStatus.indexes[1] === '0') {
        this.questionOneCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '2', '0', 'thirdPartQuestionOne');
        this.hideCheckButton = this.questionOneCorrect;
        this.hideGoOnButton = !this.hideCheckButton;
      }
    },
  },
  async mounted() {
    this.thirdPartQuestionOne = await questionApi.questionArray.getPartAndIndexQuestion(2, 0);
  },
};
</script>

<style scoped>
</style>

<template>
<div>
    <img alt="Home Image" src="../assets/Face_1.png">
    <p style="margin-top: 30px;">Okey das war bisschen zu einfach :)</p>
    <b-button variant="success" v-on:click="goToSecondPart">Weiter</b-button>
</div>
</template>

<script>
export default {
  name: 'FirstHint',
  props: {
  },
  methods: {
    goToSecondPart() {
      this.$router.push('/5844f641-96cc-425c-bb46-78039dc92941');
    },
  },
};
</script>

<style scoped>
p {
    margin-left: 10px;
    margin-right: 10px;
}
</style>

<template>
  <div>
    <div>
      <Question id="sixthPartQuestionOne" :question=this.sixthPartQuestionOne
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="sixthPartQuestionTwo" :question=this.sixthPartQuestionTwo
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="sixthPartQuestionThree" :question=this.sixthPartQuestionThree
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="sixthPartQuestionFour" :question=this.sixthPartQuestionFour
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="sixthPartQuestionFive" :question=this.sixthPartQuestionFive
                v-on:is-correct="checkStatusOfQuestion"/>
    </div>
    <b-button variant="warning" v-on:click="checkIfCorrect"
              :hidden="hideCheckButton" style="border-color: black; margin-top: 30px">Prüfen
    </b-button>
    <b-button variant="success" v-on:click="goToSixthHint" :hidden="hideGoOnButton"
              style="border-color: black; margin-top: 30px">Weiter
    </b-button>
  </div>
</template>

<script>
import Question from '../components/Question.vue';
import questionApi from '../questionApi';
import api from '../questionCheckerAndChanger';

export default {
  name: 'SixthPart',
  components: {
    Question,
  },
  data() {
    return {
      sixthPartQuestionOne: {},
      sixthPartQuestionTwo: {},
      sixthPartQuestionThree: {},
      sixthPartQuestionFour: {},
      sixthPartQuestionFive: {},
      questionOneCorrect: false,
      questionTwoCorrect: false,
      questionThreeCorrect: false,
      questionFourCorrect: false,
      questionFiveCorrect: false,
      hideCheckButton: false,
      hideGoOnButton: true,
    };
  },
  methods: {
    checkIfCorrect() {
      if (this.questionOneCorrect && this.questionTwoCorrect && this.questionThreeCorrect
        && this.questionFourCorrect && this.questionFiveCorrect) {
        this.hideCheckButton = true;
        this.hideGoOnButton = false;
      }
    },
    goToSixthHint() {
      this.$router.push('/b17940c7-0be0-4e45-b42f-8ba8f46affc4');
    },
    checkStatusOfQuestion(questionStatus) {
      if (questionStatus.indexes[0] === '5' && questionStatus.indexes[1] === '0') {
        this.questionOneCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '5', '0', 'sixthPartQuestionOne');
      }
      if (questionStatus.indexes[0] === '5' && questionStatus.indexes[1] === '1') {
        this.questionTwoCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '5', '1', 'sixthPartQuestionTwo');
      }
      if (questionStatus.indexes[0] === '5' && questionStatus.indexes[1] === '2') {
        this.questionThreeCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '5', '2', 'sixthPartQuestionThree');
      }
      if (questionStatus.indexes[0] === '5' && questionStatus.indexes[1] === '3') {
        this.questionFourCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '5', '3', 'sixthPartQuestionFour');
      }
      if (questionStatus.indexes[0] === '5' && questionStatus.indexes[1] === '4') {
        this.questionFiveCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '5', '4', 'sixthPartQuestionFive');
      }
    },
  },
  async mounted() {
    this.sixthPartQuestionOne = await questionApi.questionArray.getPartAndIndexQuestion(5, 0);
    this.sixthPartQuestionTwo = await questionApi.questionArray.getPartAndIndexQuestion(5, 1);
    this.sixthPartQuestionThree = await questionApi.questionArray.getPartAndIndexQuestion(5, 2);
    this.sixthPartQuestionFour = await questionApi.questionArray.getPartAndIndexQuestion(5, 3);
    this.sixthPartQuestionFive = await questionApi.questionArray.getPartAndIndexQuestion(5, 4);
  },
};
</script>

<style scoped>
</style>

function disableAndChangeBgColorOfInput(id) {
  document
    .getElementById(id)
    .getElementsByTagName('input')[0]
    .style
    .backgroundColor = 'lawngreen';
  document
    .getElementById(id)
    .getElementsByTagName('input')[0]
    .disabled = true;
}

function changeBgColorOfInput(id) {
  document
    .getElementById(id)
    .getElementsByTagName('input')[0]
    .style
    .backgroundColor = 'red';
}

const questionCheckerAndChanger = {
  checkStatusOfQuestion(questionStatus, index0, index1, id) {
    if (questionStatus.correct) {
      if (questionStatus.indexes[0] === index0 && questionStatus.indexes[1] === index1) {
        disableAndChangeBgColorOfInput(id);
      }
      return true;
    } if (!questionStatus.correct) {
      if (questionStatus.indexes[0] === index0 && questionStatus.indexes[1] === index1) {
        changeBgColorOfInput(id);
      }
      return false;
    }
    return false;
  },
};

const api = { questionCheckerAndChanger };

export default api;

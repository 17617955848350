import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import FirstPart from '../views/FirstPart.vue';
import FirstHint from '../views/FirstHint.vue';
import SecondPart from '../views/SecondPart.vue';
import SecondHint from '../views/SecondHint.vue';
import ThirdPart from '../views/ThirdPart.vue';
import ThirdHint from '../views/ThirdHint.vue';
import FourthPart from '../views/FourthPart.vue';
import FourthHint from '../views/FourthHint.vue';
import FifthPart from '../views/FifthPart.vue';
import FifthHint from '../views/FifthHint.vue';
import SixthPart from '../views/SixthPart.vue';
import SixthHint from '../views/SixthHint.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ef8f418e-90d2-11ec-b909-0242ac120002',
    name: 'FirstPart',
    component: FirstPart,
  },
  {
    path: '/2c04cf6c-90d3-11ec-b909-0242ac120002',
    name: 'FirstHint',
    component: FirstHint,
  },
  {
    path: '/5844f641-96cc-425c-bb46-78039dc92941',
    name: 'SecondPart',
    component: SecondPart,
  },
  {
    path: '/4034aa47-5487-47a3-b5ab-fb13bdb6458b',
    name: 'SecondHint',
    component: SecondHint,
  },
  {
    path: '/b1d31558-6589-4084-b995-40bbffd92a98',
    name: 'ThirdPart',
    component: ThirdPart,
  },
  {
    path: '/72a2520e-2817-44c2-b502-71ce86b3f490',
    name: 'ThirdHint',
    component: ThirdHint,
  },
  {
    path: '/de068601-6c74-4ff5-afa6-62f64f2041f3',
    name: 'FourthPart',
    component: FourthPart,
  },
  {
    path: '/4e49ac79-37c2-4ea7-b693-4428cc067c43',
    name: 'FourthHint',
    component: FourthHint,
  },
  {
    path: '/8d4245f8-f554-4053-bf32-6bad4ba6be25',
    name: 'FifthPart',
    component: FifthPart,
  },
  {
    path: '/971ed08b-0012-4ecd-9771-e8df1098a1ea',
    name: 'FifthHint',
    component: FifthHint,
  },
  {
    path: '/ae648d90-721a-4c22-8ade-631953d238d3',
    name: 'SixthPart',
    component: SixthPart,
  },
  {
    path: '/b17940c7-0be0-4e45-b42f-8ba8f46affc4',
    name: 'SixthHint',
    component: SixthHint,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
<div>
  <img alt="Home Image" src="../assets/Face_4.png">
    <p>Oha Baby du bist ja richtig gut!</p>
    <p>Bitte jetzt Bescheid geben!</p>
</div>
</template>

<script>
export default {
  name: 'FourthHint',
  props: {
  },
  methods: {
    goToFifthPart() {
      this.$router.push('/8d4245f8-f554-4053-bf32-6bad4ba6be25');
    },
  },
};
</script>

<style scoped>
p {
    margin-left: 10px;
    margin-right: 10px;
}
</style>

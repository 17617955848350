<template>
<div>
  <img alt="Home Image" src="../assets/Face_5.png">
  <p>Da fehlen wohl noch ein paar Puzzleteil :(</p>
  <p>Bitte jetzt Bescheid geben!</p>
</div>
</template>

<script>
export default {
  name: 'FifthHint',
  props: {
  },
  methods: {
    goToSixthPart() {
      this.$router.push('/ae648d90-721a-4c22-8ade-631953d238d3');
    },
  },
};
</script>

<style scoped>
p {
    margin-left: 10px;
    margin-right: 10px;
}
</style>

<template>
<div>
    <img alt="Home Image" src="../assets/Face_2.png">
    <p style="margin-top: 40px; margin-left: 30px; margin-right: 30px">
      Gott sei Dank hast du an dem Tag keine geraucht Babe!</p>
    <p>Bitte jetzt Bescheid geben!</p>
</div>
</template>

<script>
export default {
  name: 'SecondHint',
  props: {
  },
  methods: {
    goToThirdPart() {
      this.$router.push('/b1d31558-6589-4084-b995-40bbffd92a98');
    },
  },
};
</script>

<style scoped>
p {
    margin-left: 10px;
    margin-right: 10px;
}
</style>

<template>
  <div>
    <div>
      <Question id="secondPartQuestionOne" :question=this.secondPartQuestionOne
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="secondPartQuestionTwo" :question=this.secondPartQuestionTwo
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="secondPartQuestionThree" :question=this.secondPartQuestionThree
                v-on:is-correct="checkStatusOfQuestion"/>
    </div>
    <b-button variant="warning" v-on:click="checkIfCorrect"
              :hidden="hideCheckButton" style="border-color: black; margin-top: 30px">Prüfen
    </b-button>
    <b-button variant="success" v-on:click="goToSecondHint" :hidden="hideGoOnButton"
              style="border-color: black; margin-top: 30px">Weiter
    </b-button>
  </div>
</template>

<script>
import Question from '../components/Question.vue';
import questionApi from '../questionApi';
import api from '../questionCheckerAndChanger';

export default {
  name: 'SecondPart',
  components: {
    Question,
  },
  data() {
    return {
      secondPartQuestionOne: {},
      secondPartQuestionTwo: {},
      secondPartQuestionThree: {},
      questionOneCorrect: false,
      questionTwoCorrect: false,
      questionThreeCorrect: false,
      hideCheckButton: false,
      hideGoOnButton: true,
    };
  },
  methods: {
    checkIfCorrect() {
      if (this.questionOneCorrect && this.questionTwoCorrect && this.questionThreeCorrect) {
        this.hideCheckButton = true;
        this.hideGoOnButton = false;
      }
    },
    goToSecondHint() {
      this.$router.push('/4034aa47-5487-47a3-b5ab-fb13bdb6458b');
    },
    checkStatusOfQuestion(questionStatus) {
      if (questionStatus.indexes[0] === '1' && questionStatus.indexes[1] === '0') {
        this.questionOneCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '1', '0', 'secondPartQuestionOne');
      }
      if (questionStatus.indexes[0] === '1' && questionStatus.indexes[1] === '1') {
        this.questionTwoCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '1', '1', 'secondPartQuestionTwo');
      }
      if (questionStatus.indexes[0] === '1' && questionStatus.indexes[1] === '2') {
        this.questionThreeCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '1', '2', 'secondPartQuestionThree');
      }
    },
  },
  async mounted() {
    this.secondPartQuestionOne = await questionApi.questionArray.getPartAndIndexQuestion(1, 0);
    this.secondPartQuestionTwo = await questionApi.questionArray.getPartAndIndexQuestion(1, 1);
    this.secondPartQuestionThree = await questionApi.questionArray.getPartAndIndexQuestion(1, 2);
  },
};
</script>

<style scoped>
</style>

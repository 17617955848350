import questions from './assets/questions.json';

const readQuestions = JSON.parse(JSON.stringify(questions));

const questionArray = {
  async getPartAndIndexQuestion(part, index) {
    return readQuestions[part].questions[index];
  },
};

const questionApi = { questionArray };

export default questionApi;

<template>
  <div class="question" style="margin-bottom: 20px;">
    <p style="margin-left: 10px; margin-right: 10px">{{ question.question }}</p>
    <input id="question_input" v-model="input" @change="onInputChange"/>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    question: Object,
  },
  data() {
    return {
      input: '',
    };
  },
  methods: {
    onInputChange() {
      console.log(`Input: ${this.input}`);
      console.log(`Answer: ${this.question.answer}`);
      console.log(`Correct: ${this.question.answer.trim().toLowerCase() === this.input.trim().toLowerCase()}`);
      if (this.question.answer.trim().toLowerCase() === this.input.trim().toLowerCase()) {
        this.$emit('is-correct', this.extractIndexes(true));
      } else {
        this.$emit('is-correct', this.extractIndexes(false));
      }
    },
    extractIndexes(state) {
      return {
        correct: state,
        indexes: [this.question.id.charAt(0), this.question.id.charAt(2)],
      };
    },
  },
};
</script>

<style scoped>
.question input {
  width: 80%;
}
</style>

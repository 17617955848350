<template>
<div>
    <img alt="Home Image" src="../assets/Face_3.png">
    <p>Du bisch einfach brutal ej!</p>
    <p>Bitte jetzt Bescheid geben!</p>
</div>
</template>

<script>
export default {
  name: 'ThirdHint',
  props: {
  },
  methods: {
    goToFourthPart() {
      this.$router.push('/de068601-6c74-4ff5-afa6-62f64f2041f3');
    },
  },
};
</script>

<style scoped>
p {
    margin-left: 10px;
    margin-right: 10px;
}
</style>

<template>
  <div>
    <div>
      <Question id="fourthPartQuestionOne" :question=this.fourthPartQuestionOne
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="fourthPartQuestionTwo" :question=this.fourthPartQuestionTwo
                v-on:is-correct="checkStatusOfQuestion"/>
      <Question id="fourthPartQuestionThree" :question=this.fourthPartQuestionThree
                v-on:is-correct="checkStatusOfQuestion"/>
    </div>
    <b-button variant="warning" v-on:click="checkIfCorrect"
              :hidden="hideCheckButton" style="border-color: black; margin-top: 30px">Prüfen
    </b-button>
    <b-button variant="success" v-on:click="goToFourthHint" :hidden="hideGoOnButton"
              style="border-color: black; margin-top: 30px">Weiter
    </b-button>
  </div>
</template>

<script>
import Question from '../components/Question.vue';
import questionApi from '../questionApi';
import api from '../questionCheckerAndChanger';

export default {
  name: 'FourthPart',
  components: {
    Question,
  },
  data() {
    return {
      fourthPartQuestionOne: {},
      fourthPartQuestionTwo: {},
      fourthPartQuestionThree: {},
      questionOneCorrect: false,
      questionTwoCorrect: false,
      questionThreeCorrect: false,
      hideCheckButton: false,
      hideGoOnButton: true,
    };
  },
  methods: {
    checkIfCorrect() {
      if (this.questionOneCorrect && this.questionTwoCorrect && this.questionThreeCorrect) {
        this.hideCheckButton = true;
        this.hideGoOnButton = false;
      }
    },
    goToFourthHint() {
      this.$router.push('/4e49ac79-37c2-4ea7-b693-4428cc067c43');
    },
    checkStatusOfQuestion(questionStatus) {
      if (questionStatus.indexes[0] === '3' && questionStatus.indexes[1] === '0') {
        this.questionOneCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '3', '0', 'fourthPartQuestionOne');
      }
      if (questionStatus.indexes[0] === '3' && questionStatus.indexes[1] === '1') {
        this.questionTwoCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '3', '1', 'fourthPartQuestionTwo');
      }
      if (questionStatus.indexes[0] === '3' && questionStatus.indexes[1] === '2') {
        this.questionThreeCorrect = api
          .questionCheckerAndChanger
          .checkStatusOfQuestion(questionStatus, '3', '2', 'fourthPartQuestionThree');
      }
    },
  },
  async mounted() {
    this.fourthPartQuestionOne = await questionApi.questionArray.getPartAndIndexQuestion(3, 0);
    this.fourthPartQuestionTwo = await questionApi.questionArray.getPartAndIndexQuestion(3, 1);
    this.fourthPartQuestionThree = await questionApi.questionArray.getPartAndIndexQuestion(3, 2);
  },
};
</script>

<style scoped>
</style>
